/**
 * Hexio App Engine Function extensions base library.
 *
 * @package hae-ext-functions-base
 * @copyright 2021 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import { ItemRegistry } from "@hexio_io/hae-lib-shared";
import { TGenericFunctionDefinition } from "@hexio_io/hae-lib-blueprint";
import { ITranslationManager } from "@hexio_io/hae-lib-core";

import {
	absFunc,
	andFunc,
	arrConcatFunc,
	arrFilterFunc,
	arrFindFunc,
	arrFlatFunc,
	arrMapFunc,
	arrPopFunc,
	arrPushFunc,
	arrReduceFunc,
	arrSliceFunc,
	arrSortFunc,
	arrSpliceFunc,
	arrSumFunc,
	base64DecodeFunc,
	base64EncodeFunc,
	bemFunc,
	ceilFunc,
	classlistFunc,
	concatFunc,
	containsFunc,
	cryptoMd5HashFunc,
	cryptoSha256HashFunc,
	cryptoSha256HmacFunc,
	dateAddFunc,
	dateDiffFunc,
	dateFormatFunc,
	dateNowFunc,
	dateParseFunc,
	emptyNullFunc,
	floorFunc,
	formatNumber,
	genRangeFunc,
	genUuidFunc,
	ifFunc,
	includesFunc,
	isArray,
	isBool,
	isEmptyFunc,
	isFiniteFunc,
	isNaNFunc,
	isNull,
	isNumber,
	isObject,
	isString,
	joinFunc,
	jsonIsValid,
	jsonParse,
	jsonStringify,
	lambdaFunc,
	letFunc,
	lowerCaseFunc,
	matchRegexpFunc,
	maxArrFunc,
	maxFunc,
	minArrFunc,
	minFunc,
	objDeleteKey,
	objFromArray,
	objKeys,
	objSetKey,
	orFunc,
	parseFloatFunc,
	parseIntFunc,
	parseJwt,
	propsToArray,
	qsParse,
	qsSerialize,
	replaceFunc,
	replaceRegexpFunc,
	roundFunc,
	scopedTemplateFunc,
	splitFunc,
	startsWithFunc,
	strInsertFunc,
	strlenFunc,
	substringFunc,
	switchFuc,
	toFixedFunc,
	toStringFunc,
	upperCaseFunc,
	uriDecodeComponentFunc,
	uriDecodeFunc,
	uriEncodeComponentFunc,
	uriEncodeFunc
} from "./functions";
import { xmlBuild, xmlIsValid, xmlParse } from "./functions/xml";

// Export registration functions
export function registerFunctions(
	registry: ItemRegistry<TGenericFunctionDefinition>,
	translationManager: ITranslationManager
): void {
	registry.register(absFunc);
	registry.register(andFunc);
	registry.register(arrConcatFunc);
	registry.register(arrFilterFunc);
	registry.register(arrFindFunc);
	registry.register(arrFlatFunc);
	registry.register(arrMapFunc);
	registry.register(arrPopFunc);
	registry.register(arrPushFunc);
	registry.register(arrReduceFunc);
	registry.register(arrSliceFunc);
	registry.register(arrSortFunc);
	registry.register(arrSpliceFunc);
	registry.register(arrSumFunc);
	registry.register(base64DecodeFunc);
	registry.register(base64EncodeFunc);
	registry.register(bemFunc);
	registry.register(ceilFunc);
	registry.register(classlistFunc);
	registry.register(concatFunc);
	registry.register(containsFunc);
	registry.register(cryptoMd5HashFunc);
	registry.register(cryptoSha256HashFunc);
	registry.register(cryptoSha256HmacFunc);
	registry.register(dateDiffFunc);
	registry.register(dateFormatFunc);
	registry.register(dateNowFunc);
	registry.register(dateParseFunc);
	registry.register(dateAddFunc);
	registry.register(emptyNullFunc);
	registry.register(floorFunc);
	registry.register(formatNumber);
	registry.register(genRangeFunc);
	registry.register(genUuidFunc);
	registry.register(ifFunc);
	registry.register(includesFunc);
	registry.register(isArray);
	registry.register(isBool);
	registry.register(isEmptyFunc);
	registry.register(isFiniteFunc);
	registry.register(isNaNFunc);
	registry.register(isNull);
	registry.register(isNumber);
	registry.register(isObject);
	registry.register(isString);
	registry.register(joinFunc);
	registry.register(jsonIsValid);
	registry.register(jsonParse);
	registry.register(jsonStringify);
	registry.register(lambdaFunc);
	registry.register(letFunc);
	registry.register(lowerCaseFunc);
	registry.register(matchRegexpFunc);
	registry.register(maxArrFunc);
	registry.register(maxFunc);
	registry.register(minArrFunc);
	registry.register(minFunc);
	registry.register(objDeleteKey);
	registry.register(objFromArray);
	registry.register(objKeys);
	registry.register(objSetKey);
	registry.register(orFunc);
	registry.register(parseFloatFunc);
	registry.register(parseIntFunc);
	registry.register(parseJwt);
	registry.register(propsToArray);
	registry.register(qsParse);
	registry.register(qsSerialize);
	registry.register(replaceFunc);
	registry.register(replaceRegexpFunc);
	registry.register(roundFunc);
	registry.register(scopedTemplateFunc);
	registry.register(splitFunc);
	registry.register(startsWithFunc);
	registry.register(strInsertFunc);
	registry.register(strlenFunc);
	registry.register(substringFunc);
	registry.register(switchFuc);
	registry.register(toFixedFunc);
	registry.register(toStringFunc);
	registry.register(upperCaseFunc);
	registry.register(uriDecodeComponentFunc);
	registry.register(uriDecodeFunc);
	registry.register(uriEncodeComponentFunc);
	registry.register(uriEncodeFunc);
	registry.register(xmlBuild);
	registry.register(xmlIsValid);
	registry.register(xmlParse);

	// @todo Register translation terms
}
